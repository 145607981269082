import React from "react"
import Layout from "../app/app-components/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import { HiCurrencyRupee } from "react-icons/hi"
import { FaUser, FaUserFriends, FaUserGraduate } from "react-icons/fa"
import { BsFillBriefcaseFill, BsGraphUp, BsGlobe } from "react-icons/bs"
import { RiUserSharedFill } from "react-icons/ri"
import Notifications from "../app/app-components/NotificationCard"
// import ExamGroupsCard from "../app/app-components/ExamGroupsCard"
import { motion, AnimatePresence } from "framer-motion"
import { myAnimations } from "../context/framer-animations"
import { ExamStructureCard } from "./ExamStructureCard"
import PopularExams from "../app/app-components/PopularExams"
import { EnrollButton } from "../app/app-components/EnrollButton"
import Markdown from "react-markdown"

const InstructionsTemplate = props => {
  const exam = props.pageContext
  const examIcon = exam.icon.localFile.childImageSharp.gatsbyImageData

  return (
    <Layout isBackButton={true} title={exam?.examName}>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key={props.location.pathname}
          {...myAnimations.fadeIn}
          className="pb-24 m-5 space-y-4 "
        >
          <QuizTotalInfoCard title={exam?.examName} exam={exam} />
          <div className="pt-5 font-base">Attempt</div>
          {exam?.structure.map((phase, idx) => (
            <ExamStructureCard
              key={phase?.phase}
              phase={phase}
              idx={idx}
              papers={phase.papers}
              icon={examIcon}
            />
          ))}
          <div className="pt-5 mb-3 font-base">Notifications</div>
          <Notifications examId={exam.examId} limit={2} />
          <div className="pt-5 mb-3 font-base">Details</div>
          <InfoCard exam={exam} />
          <div className="pt-5 mb-3 font-base">More exams in this Pack</div>
          <PopularExams limit={8} group={exam.examgroups[0]?.name} />
          {/* <ExamGroupsCard examId={exam.examId} /> */}
          <div className="flex pt-8">
            <EnrollButton examId={exam.examId} isBiggest={true} />
          </div>
        </motion.div>
      </AnimatePresence>
    </Layout>
  )
}

function QuizTotalInfoCard({ title, exam }) {
  return (
    <div className="flex flex-col p-4 bg-white shadow rounded-xl ">
      <div className="flex items-center justify-center">
        <div className="justify-center w-16 h-16 rounded-full left-1/2">
          {exam?.icon && (
            <GatsbyImage
              image={exam.icon.localFile.childImageSharp.gatsbyImageData}
              alt={exam.examId}
            />
          )}
        </div>
      </div>
      <div className="flex justify-center mt-2 mb-1">
        <p className="text-xl font-base ">{title}</p>
      </div>
      <div className="flex justify-center mb-4">
        <p className="px-2 text-xs text-blue-600 bg-blue-100 rounded-full">
          {exam.profession?.sector}
        </p>
      </div>
      <StatsCard exam={exam} />
    </div>
  )
}

function InfoCard({ exam }) {
  return (
    <div className="flex flex-col p-4 bg-white shadow rounded-xl ">
      <div className="flex flex-col space-y-4 text-sm">
        <div className="space-y-2 ">
          <div className="flex items-center justify-start space-x-2 font-base">
            <BsFillBriefcaseFill className="w-4 h-4 text-blue-500" />
            <p>about</p>
          </div>
        </div>
        <div className="pl-6">
          <Markdown children={exam.examData?.info} />
        </div>
        <div className="space-y-2 ">
          <div className="flex items-center justify-start pt-2 space-x-2 font-base">
            <BsGraphUp className="w-4 h-4 text-blue-500" />
            <p>career outlook</p>
          </div>
          <div className="pl-6">
            <Markdown children={exam.examData?.careerOutlook} />
          </div>
        </div>
        <div className="space-y-2 ">
          <div className="flex items-center justify-start pt-2 space-x-2 font-base">
            <HiCurrencyRupee className="w-5 h-5 text-blue-500" />
            <p>starting salary</p>
          </div>
          <p className="pl-6">Rs.{exam.examData?.salary} onwards</p>
          <div className="pl-6">
            <Markdown children={exam.examData?.salaryBreakup} />
          </div>
        </div>
        <div className="space-y-2 ">
          <div className="flex items-center justify-start pt-2 space-x-2 font-base">
            <RiUserSharedFill className="w-4 h-4 text-blue-500" />
            <p>age for apply</p>
          </div>
          <p className="pl-6">
            {exam?.ageMin} to {exam?.ageMax} years
          </p>
        </div>
        <div className="space-y-2 ">
          <div className="flex items-center justify-start pt-2 space-x-2 font-base">
            <BsGlobe className="w-4 h-4 text-blue-500" />
            <p>website</p>
          </div>
          <a
            href={`https://${exam.examData?.website}`}
            target="_blank"
            className="pl-6 underline"
          >
            {exam.examData?.website}
          </a>
        </div>
        <div className="space-y-2 ">
          <div className="flex items-center justify-start pt-2 space-x-2 font-base">
            <FaUserGraduate className="w-4 h-4 text-blue-500" />
            <p>job titles</p>
          </div>
          <div className="pl-6">
            <Markdown children={exam?.jobTitles} />
          </div>
        </div>
      </div>
    </div>
  )
}

function StatsCard({ exam }) {
  return (
    <div className="flex items-center justify-around mt-1 ">
      <div className="flex flex-col items-center ">
        <HiCurrencyRupee className="w-6 h-6 text-purple-400" />
        <p className="text-gray-800 text-md font-base">
          {exam.examData?.salary}
        </p>
        <p className="text-xs text-gray-400 ">start salary</p>
      </div>
      <div className="flex flex-col items-center ">
        <FaUser className="w-6 h-6 text-green-400" />
        <p className="text-gray-800 text-md font-base">
          {exam.examData?.vacancy}
        </p>
        <p className="text-xs text-gray-400 ">avg vacancy</p>
      </div>
      <div className="flex flex-col items-center ">
        <FaUserFriends className="w-6 h-6 text-red-400" />
        <p className="text-gray-800 text-md font-base">
          {exam.examData?.totalApplicants / 100000} lakh
        </p>
        <p className="text-xs text-gray-400 ">applicants</p>
      </div>
    </div>
  )
}

export default InstructionsTemplate
