import React from "react"
import _ from "lodash"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
// import { HiOutlineArrowNarrowRight } from "react-icons/hi"
import { useProfileStore } from "../context/profileStore"

export function ExamStructureCard({ phase, papers, icon, idx }) {
  const optionalPapers =
    (phase?.optionalExams && phase.optionalExams.split(",")) || []
  const optional = optionalPapers.map(item => item.trim())
  const sortedPapers = _.sortBy(papers, "paperId")

  // console.log(papers, phase, idx)

  const prevAttempt = useProfileStore(state => state.prevAttempt)

  function AttemptCount(paperId) {
    const filtered = _.filter(prevAttempt, { paperId: paperId })
    const attemptCount = filtered?.length > 0 ? filtered.length : 0
    return attemptCount
  }

  return (
    <div className="flex flex-col rounded-xl">
      {sortedPapers.map((paper, index) => {
        const isIndex = sortedPapers.length > 1
        const isOptional = optional?.includes(paper.paperId)
        const attemptCount = AttemptCount(paper.paperId)

        return (
          <Link
            key={paper.paperId}
            to={`/app/papers/${paper.slug}`}
            className="z-10 flex flex-col p-4 mb-2 bg-white shadow rounded-xl"
          >
            <div className="flex items-center mb-2">
              <p
                className={`px-2 text-xs text-white mr-2  rounded ${
                  idx === 1 ? "bg-pink-500" : "bg-purple-500"
                }`}
              >
                {phase?.phase}
              </p>
              {isIndex && (
                <div className="flex items-center justify-center flex-none w-4 h-4 mr-2 text-xs bg-gray-200 rounded-full">
                  {index + 1}
                </div>
              )}
              {isOptional && (
                <p className="px-2 text-xs text-blue-500 bg-blue-100 rounded ">
                  Optional
                </p>
              )}
            </div>

            <div className="z-30 flex items-center justify-between">
              <div className="flex items-center justify-start ">
                <div className="flex items-center justify-center flex-none w-12 mr-4">
                  {icon && <GatsbyImage image={icon} alt={paper.paperName} />}
                </div>
                <div className="flex-flex-col">
                  <p className="text-lg font-base">{paper?.displayName}</p>
                  <p className="text-sm text-gray-500">
                    {attemptCount} completed
                  </p>
                </div>
              </div>
              <div className="btn">Attempt</div>
              {/* <HiOutlineArrowNarrowRight className="w-8 ml-2 text-blue-500" /> */}
            </div>
          </Link>
        )
      })}
    </div>
  )
}

export function ExamStructurePapersCard({
  paper,
  isOptional,
  index,
  isIndex,
  icon,
}) {
  return (
    <Link
      to={`/app/papers/${paper.slug}`}
      className="flex flex-col p-4 mt-2 bg-white shadow rounded-xl"
    >
      {isOptional && (
        <p className="w-16 px-2 mb-2 text-xs text-blue-500 bg-blue-100 rounded">
          Optional
        </p>
      )}

      <div className="flex items-center justify-start">
        <div className="flex flex-col items-center justify-center flex-none w-8 mr-2">
          {icon && <GatsbyImage image={icon} alt={paper.paperName} />}
        </div>
        <div className="flex items-center justify-start ">
          {isIndex && (
            <div className="flex items-center justify-center flex-none w-4 h-4 mr-2 text-xs bg-gray-200 rounded-full">
              {index + 1}
            </div>
          )}
          <p className="text-sm font-semibold">{paper?.paperName}</p>
        </div>
      </div>
      <div className="flex justify-between hidden ">
        <Link to={`/papers/${paper.paperId}`} className="px-8 btn">
          Start
        </Link>
      </div>
    </Link>
  )
}
